<template>
    <div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-sm btn-primary float-right" @click="addNew()">
                    Add Container
                </button>
            </div>
            <div class="col-12">
                <div class="card card-custom gutter-b">
                    <div class="card-body body-fluid">

                        <div class="table-responsive">
                            <div>
                                <b-table :items="containers" :fields="fields" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" responsive="sm">
                                    <template #cell(airwaybill)="data">
                                        <a class="a" @click="GetRecord(data.item)">{{ data.value }}</a>
                                    </template>
                                    <template #cell(actions)="row">
                                        <b-button @click="viewContainer(row.item.id)" class="btn-sm" variant="primary">
                                            View</b-button>
                                        <!-- <b-button @click="deleteContainer(row.item.id)" class="btn-sm ml-2"
                                            variant="danger">Delete</b-button> -->
                                    </template>
                                </b-table>

                                <div>
                                    Sorting By: <b>{{ sortBy }}</b>, Sort Direction:
                                    <b>{{ sortDesc ? 'Descending' : 'Ascending' }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            containers: [],
            // sortBy: 'age',
            sortDesc: false,
            fields: [{
                key: "id",
                label: "ID",
                sortable: true,
            },
            {
                key: "airwaybill",
                label: "Name / AWB NO",
                sortable: true,
            },
            {
                key: "ortodes",
                label: "Origin -> Destination",
                sortable: true,
            },
            // {
            //     key: "destination",
            //     label: "Destination",
            //     sortable: true,
            // },
            {
                key: "ortodesdate",
                label: "Departure -> Arrival",
                // formatter: (value) => {
                //     return new Date(value).toLocaleString();
                // },
                sortable: false,
            },
            // {
            //     key: "arrivaldate",
            //     label: "Arrival Date",
            //     formatter: (value) => {
            //         return new Date(value).toLocaleString();
            //     },
            //     sortable: false,
            // },
            {
                key: "flightno",
                sortable: false,
            },
            {
                key: "shipmentcount",
                label: "Shipments count",
                sortable: false,
            },
            {
                key: "actions",
                label: "Actions",
            },
            ],
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    components: {
        Loading,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Container",
        },
        {
            title: "All Containers",
        },
        ]);
        this.getActiveContainers();
    },
    methods: {
        addNew() {
            this.$router.push({
                name: "add",
            });
        },
        GetRecord(row) {
            this.$router.push({
                name: "viewcontainer",
                params: {
                    id: row.id
                }
            });
        },
        getActiveContainers() {
            ApiService.get("container")
                .then(({
                    data
                }) => {
                    this.containers = data;
                    this.rows = this.shipments.length;
                    this.isLoading = false;
                })
                .catch(() => { });
        },
        makeToastVariant(variant = null, text) {
            this.$bvToast.toast(text, {
                title: `Error`,
                variant: variant,
                solid: true,
            });
        },
        viewContainer(row) {

            this.$router.push({
                name: "viewcontainer",
                params: {
                    id: row
                }
            });
        },

        // deleteContainer(row) {
        //     this.$router.push({
        //         name: "viewcontainer",
        //         params: {
        //             id: row
        //         }
        //     });
        // }
    },
};
</script>
